import React from "react";
import heroImg from '../../img/home-img.png';
import './hero.css';

export const Hero = () => {
    
  return (
    <section id="hero" className="d-flex align-items-center">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <h1>Gereb Technologies PLC Innovative Minds!</h1>
            <h2>
            If you are thinking of building a software, an app, or any network installation,
             look no further than Gereb Technologies. We are your trusted partner for turning your ideas into reality. 
             With our expertise and innovative solutions, we bring your vision to life, ensuring excellence in every step of the process. 
             Stay ahead in a rapidly evolving marketplace with Gereb Technologies by your side,
             empowering you to achieve your digital goals with confidence.            </h2>
          </div>
          <div
            className="col-lg-6 order-1 order-lg-2 hero-img"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <img
              src={heroImg}
              className="img-fluid animated"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
