import React, { useState } from "react";
import shrutiImg from '../../img/team/Shruti.png';
import melatImg from '../../img/team/melat.png';
import birukImg from '../../img/team/biruk.png';
import edenImg from '../../img/team/eden.png';
import israelImg from '../../img/team/israel.jpg';
import bettyImg from '../../img/team/betty.jpg';
import nuhaminImg from '../../img/team/nuhamin.jpg';

import './team.css';

export const Team = () => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <section id="team" className="team section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Get to Know Us</h2>
          <p>
            We would like to introduce ourselves to you and would be happy to connect and talk to you!
          </p>
        </div>

        <div className="row">
          <div className="col-md-6 mt-4">
            <div
              className="member d-flex align-items-start"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div className="pic">
                <img
                  src={shrutiImg}
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="member-info">
                <h4>Roha Abadi</h4>
                <span>Founder and CEO</span>
                <p>
                  Meet Roha Abadi, the visionary founder and CEO of Gereb Technologies. Passionate about innovation, Roha has propelled Gereb to the forefront of the tech industry. Under his dynamic leadership, the company thrives on a culture of innovation, achieving notable success. Beyond the boardroom, Roha actively engages in community initiatives, embodying the values that drive Gereb's success.
                </p>
                <div className="social">
                  <a href="https://www.facebook.com/abushom" target="noopener noreferrer">
                    <i className="ri-facebook-fill"></i>
                  </a>
                  <a href="https://www.instagram.com/rohaabadi/" target="noopener noreferrer">
                    <i className="ri-instagram-fill"></i>
                  </a>
                  <a href="https://www.linkedin.com/in/roha-a-b1115936/" target="noopener noreferrer">
                    {" "}
                    <i className="ri-linkedin-box-fill"></i>{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 mt-4">
            <div
              className="member d-flex align-items-start"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div className="pic">
                <img
                  src={melatImg}
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="member-info">
                <h4>Melat Temesgen</h4>
                <span> Office Manager and Finance </span>
                <p>
                  Meet Melat Temesgen, our adept Finance and Office Manager. With a strong background in finance and office administration, Melat plays a pivotal role in maintaining our financial health and operational efficiency. Known for her meticulous attention to detail, Melat ensures seamless office functioning and is a dedicated force for fiscal responsibility and streamlined operations within our team.
                </p>
                <div className="social">
                  <a href="#" target="noopener noreferrer">
                    <i className="ri-facebook-fill"></i>
                  </a>
                  <a href="#" target="noopener noreferrer">
                    <i className="ri-instagram-fill"></i>
                  </a>
                  <a href="#" target="noopener noreferrer">
                    {" "}
                    <i className="ri-linkedin-box-fill"></i>{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 mt-4">
            <div
              className="member d-flex align-items-start"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div className="pic">
              <img
                  src={edenImg}
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="member-info">
              <h4>Eden Abera</h4>
                <span>Flutter Developer</span>
                <p>
                Meet Eden Abera, a skilled Flutter Developer proficient in Dart programming, creating visually appealing and functionally robust cross-platform mobile applications. With a passion for innovation and an eye for detail, Eden is dedicated to crafting immersive and responsive mobile experiences, standing out as a dedicated professional in the fast-paced world of app development.</p>
                <div className="social">
                  {/* <a href="https://facebook.com/shinde.shreyas?_rdr" target="noopener noreferrer">
                    <i className="ri-facebook-fill"></i>
                  </a> */}
                  <a href="https://www.instagram.com/edengenet_a/" target="noopener noreferrer">
                    <i className="ri-instagram-fill"></i>
                  </a>
                  <a href="https://www.linkedin.com/in/eden-abera-9b44281b1/?originalSubdomain=et" target="noopener noreferrer">
                    {" "}
                    <i className="ri-linkedin-box-fill"></i>{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 mt-4">
            <div
              className="member d-flex align-items-start"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div className="pic">
                <img
                  src={birukImg}
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="member-info">
                <h4>Brook Tewabe </h4>
                <span>Backend Developer</span>
              <p>  Meet Brook Tewabe, an experienced Backend Developer specializing in crafting robust, scalable systems. Dedicated to optimizing functionality and ensuring data integrity, Brook is a valuable asset for projects requiring a reliable backend infrastructure.</p>
                <div className="social">
                  <a href="https://www.facebook.com/biruk.tyemane" target="noopener noreferrer">
                    <i className="ri-facebook-fill"></i>
                  </a>
                  <a href="https://www.instagram.com/brook_t_yemane/" target="noopener noreferrer">
                    <i className="ri-instagram-fill"></i>
                  </a>
                   <a href="https://www.linkedin.com/in/shreyas-shinde-447527110/" target="noopener noreferrer">
                    <i className="ri-linkedin-box-fill"></i>{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>

          {showMore && (
            <>
          <div className="col-md-6 mt-4">
            <div
              className="member d-flex align-items-start"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div className="pic">
              <img
                  src={bettyImg}
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="member-info">
              <h4>Bethelhem Enyew</h4>
                <span>Marketing Manager</span>
                <p>
                Meet Bethelhem Enyew, a skilled Marketing Manager proficient in creating effective marketing strategies and campaigns. With a passion for innovation and an eye for detail, Bethelhem is dedicated to crafting compelling brand stories and driving business growth. Standing out as a dedicated professional in the fast-paced world of marketing, Bethelhem is committed to delivering impactful results.
</p>
                <div className="social">
                  {/* <a href="https://facebook.com/shinde.shreyas?_rdr" target="noopener noreferrer">
                    <i className="ri-facebook-fill"></i>
                  </a> */}
                  <a href="https://www.instagram.com/edengenet_a/" target="noopener noreferrer">
                    <i className="ri-instagram-fill"></i>
                  </a>
                  <a href="https://www.linkedin.com/in/eden-abera-9b44281b1/?originalSubdomain=et" target="noopener noreferrer">
                    {" "}
                    <i className="ri-linkedin-box-fill"></i>{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 mt-4">
            <div
              className="member d-flex align-items-start"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div className="pic">
              <img
                  src={israelImg}
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="member-info">
              <h4>Israel Wujira</h4>
                <span>Graphics Designer</span>
                <p>
                Meet Israel, a talented Graphics Designer proficient in creating captivating visual elements and designs. With a keen eye for aesthetics and a passion for creativity, Israel crafts compelling graphics that enhance brand identity and communication. From logos to illustrations, Israel's designs leave a lasting impression, elevating projects to new heights of excellence.</p>
                <div className="social">
                  {/* <a href="https://facebook.com/shinde.shreyas?_rdr" target="noopener noreferrer">
                    <i className="ri-facebook-fill"></i>
                  </a> */}
                  <a href="https://www.instagram.com/edengenet_a/" target="noopener noreferrer">
                    <i className="ri-instagram-fill"></i>
                  </a>
                  <a href="https://www.linkedin.com/in/eden-abera-9b44281b1/?originalSubdomain=et" target="noopener noreferrer">
                    {" "}
                    <i className="ri-linkedin-box-fill"></i>{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 mt-4">
            <div
              className="member d-flex align-items-start"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div className="pic">
              <img
                  src={nuhaminImg}
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="member-info">
              <h4>Nuhamin Tesfaye</h4>
                <span>Data Encoder</span>
                <p>
                Meet Nuhamin Tesfaye, a skilled data encoder proficient in managing and organizing data efficiently. With meticulous attention to detail, Nuhamin ensures accuracy and reliability in data processing tasks, contributing to streamlined operations and decision-making processes.</p>
                <div className="social">
                  {/* <a href="https://facebook.com/shinde.shreyas?_rdr" target="noopener noreferrer">
                    <i className="ri-facebook-fill"></i>
                  </a> */}
                  <a href="https://www.instagram.com/edengenet_a/" target="noopener noreferrer">
                    <i className="ri-instagram-fill"></i>
                  </a>
                  <a href="https://www.linkedin.com/in/nuhamin-tesfaye-9b44281b1/?originalSubdomain=et" target="noopener noreferrer">
                    {" "}
                    <i className="ri-linkedin-box-fill"></i>{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
            </>
          )}

          <div className="col-md-12 mt-4">
            <button onClick={toggleShowMore}>
              {showMore ? "Show Less" : "See More"}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
