import {React, useState} from 'react';
import './contact.css';
import emailjs from '@emailjs/browser';

export const Contact = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState(null);

  const sendEmail = (e) => {
    e.persist();
    e.preventDefault();
    setIsSubmitting(true);
    emailjs
      .sendForm(
        'service_3x8jcuo',
        'template_w51sxru',
        e.target,
        'y83w0Ca7GiWwGGWO4'
      )
      .then(
        (result) => {
          setStateMessage('Message sent!');
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // hide message after 5 seconds
          // Clears the form after sending the email
          e.target.reset();
        },
        (error) => {
          setStateMessage(error.text);
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // hide message after 5 seconds
        }
      );
    
  };
    // const handleSubmit = async (event) => {
    //   event.preventDefault(); // Prevent default submission    
    //   try {
    //     await saveFormData();      
    //     console.log(values);
    //     setValues({
    //       name: '', email: '', subject: '', body: '' 
    //     });
    //     alert('Your message sent!');
    //   } catch (e) {
    //     alert(`Request failed! ${e.message}`);
    //   }
    // }

    return (
        <section id="contact" className="contact">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Contact Us</h2>
          <p>We will be more than excited to talk to you. All your suggestions, reviews, feedback and queries will be appreciated. Do get in touch with us and we will love to hear from you!</p>
        </div>

        <div className="row">

          <div className="col-lg-5 d-flex align-items-stretch">
            <div className="info">
              <div className="address">
                <i className="bi bi-geo-alt"></i>
                <h4>Location:</h4>
                <p>Bulgaria,Tanzania St, Addis Ababa, Ethiopia </p>
              </div>

              <div className="email">
                <i className="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p>gereb.technologies@gmail.com</p>
              </div>

              <div className="phone">
                <i className="bi bi-phone"></i>
                <h4>Call:</h4>
                <p>+251939888000 </p>
              </div>

              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d246.29719868463246!2d38.74803334940224!3d8.994660983979898!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b85d3791f9b6d%3A0x5707abbf2514c325!2sGereb%20Technologies!5e0!3m2!1sen!2set!4v1702561010932!5m2!1sen!2set" frameBorder="0" style={{border:"0", width:"100%",height:"290px"}} allowFullScreen></iframe>
            </div>

          </div>

          <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
            <form className="php-email-form" onSubmit={sendEmail}>
              <div className="row">
                <div className="form-group col-md-6">
                  <label for="name">Your Name</label>
                  <input type="text" name="user_name" className="form-control" required />
                </div>
                <div className="form-group col-md-6">
                  <label for="name">Your Email</label>
                  <input type="email" className="form-control" name="user_email" required />
                </div>
              </div>
              <div className="form-group">
                <label for="name">Subject</label>
                <input type="text" className="form-control" name="subject" required />
              </div>
              <div className="form-group">
                <label for="name">Message</label>
                <textarea className="form-control" name="message" rows="10" required ></textarea>
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div className="text-center">
              <input type="submit" value="Send" disabled={isSubmitting} />
                {stateMessage && <p>{stateMessage}</p>}
              </div>
            </form>
          </div>

        </div>

      </div>
    </section>
    )
}

export default Contact;