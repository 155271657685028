import React from "react";
import './about.css';

export const About = () => {
  return (
    <section id="about" className="about section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>About Us</h2>
        </div>

        <div className="row content">
         
            <p>
  

            Welcome to Gereb Technologies, your dedicated partner for digital success. Specializing in mobile apps, websites, networks, and branding, we deliver tailored solutions to drive your business forward. With a customer-centric approach and a relentless pursuit of excellence, we guarantee your satisfaction. From concept to implementation, our team is committed to ensuring your digital assets are robust, secure, and future-proof.<br/>
Contact us today to embark on a transformative journey fueled by technology-driven growth. <br/>Let's innovate together and redefine what's possible for your business. Trust us to propel your vision forward with expertise and passion.
            </p>
        
        </div>
      </div>
    </section>
  );
};

export default About;