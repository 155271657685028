import React from 'react';
import './services.css';
import appDevelopmentImg from '../../img/services-icon/app-devlopment.png';
import contentDevelopmentImg from '../../img/services-icon/content-development.png'
import translationImg from '../../img/services-icon/translation.png'
import videoDevelopmentImg from '../../img/services-icon/video-development.png';

export const Services = () => {
    return (
        <section id="services" className="services">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Our Services</h2>
          <p>We specialize in Website Development, Mobile Application Development, Network Infrastructure Installation, Branding, and UI/UX Designing.</p>
        </div>

        <div className="row">
          <div className="col-md-6 my-4 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="100">
            <div className="icon-box">
              <div className="icon"><img src={contentDevelopmentImg} alt=""/></div>
              <h4>Mobile Application Development</h4>
              <p>We design and develop customized mobile applications for various platforms, providing seamless functionality and intuitive user interfaces.</p>
            </div>
          </div>

          <div className="col-md-6 my-4 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="200">
            <div className="icon-box">
              <div className="icon"><img src={videoDevelopmentImg} alt=""/></div>
              <h4>Website Development</h4>
              <p>We create stunning and responsive websites that cater to your specific requirements, ensuring an engaging user experience.</p>
            </div>
          </div>

          <div className="col-md-6 my-4 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="300">
            <div className="icon-box">
              <div className="icon"><img src={translationImg} alt=""/></div>
              <h4>Network Infrastructure Installation</h4>
              <p>We offer comprehensive network infrastructure installation services, ensuring reliable connectivity and optimized performance for your organization.</p>
            </div>
          </div>

          <div className="col-md-6 my-4 d-flex align-items-stretch mt-4 mt-xl-0 apps" data-aos="zoom-in" data-aos-delay="400">
            <div className="icon-box">
              <div className="icon"><img src={appDevelopmentImg} alt=""/></div>
              <h4>Branding</h4>
              <p>We provide branding services to establish a strong and cohesive brand identity, including logo design, brand guidelines, and brand strategy.</p>
            </div>
          </div>

        </div>

      </div>
    </section>
    )
}

export default Services;

