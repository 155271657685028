import React from 'react'
import './footer.css';
import {
  Link, useLocation
} from "react-router-dom";

export const Footer = () => {
  
  return (
    <footer id="footer">

      <div className="footer-top">
        <div className="container">
          <div className="row">

            <div className="col-md-4 footer-contact">
              <h3>Gereb Technologies</h3>
              <p>
              Bulgaria,Tanzania St, Addis Ababa, Ethiopia
              <br />
              Awe Bane Building 5th floor, Office number 501.
                <br />
                <strong>Phone:</strong>+251939888000 <br />
                <strong>Email:</strong> gereb.technologies@gmail.com<br />
              </p>
            </div>

            <div className="col-md-4 footer-links">
              <h4>Our Blogs</h4>
              <ul>
                <li><i className="bx bx-chevron-right"></i> <Link to="/technology">Technology</Link></li>
                <li><i className="bx bx-chevron-right"></i> <Link to="/design">Design</Link></li>
                <li><i className="bx bx-chevron-right"></i> <Link to="/content">Content</Link></li>
                <li><i className="bx bx-chevron-right"></i> <Link to="/videos">Networking</Link></li>
              
              </ul>
            </div>

            <div className="col-md-4 footer-links">
              <h4>Our Social Networks</h4>
              <p>Connect with Us!</p>
              <div className="social-links mt-3">
                <a href="https://www.facebook.com/gerebtechnologies" target="noopener noreferrer" className="facebook"><i className="bx bxl-facebook"></i></a>
                <a href="https://www.instagram.com/gerebtechnologies/" target="noopener noreferrer" className="instagram"><i className="bx bxl-instagram"></i></a>
                <a href="https://www.linkedin.com/company/gereb-technologies/" target="noopener noreferrer" className="linkedin"><i className="bx bxl-linkedin"></i></a>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="container footer-bottom clearfix">
        <div className="copyright">
          &copy; Copyright <strong><span>Gereb Technologies</span></strong>. <br/> All Rights Reserved
      </div>
        <div className="credits">
          Designed & Developed by Gereb Technologies.
      </div>
      </div>
    </footer>
  )
}

export default Footer;